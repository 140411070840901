import http from "../index"
import config from '../../config'
import axios from "axios";

const osApiURL = config.osApiURL

//获取邀请学习权限
export async function getInviteStudy(params: any) {
  const url = `${osApiURL}/api/v0.3/course/study/isInvite?repo=${params.repo}&key=${params.key}`
  return http.get(url);
}

//课程git Book检验
export async function verifyGit(params:any) {
  return http.post(`${osApiURL}/api/v0.3/git-book/link/verify`,params);
}

//博客git Book检验
export async function blogVerifyGit(params:any) {
  return http.get(`${osApiURL}/api/v0.3/git-book/blog/link/verify?target=${params.gitBookMd}`);
}

//课程git Book导入
export async function crawlGitbook(params:any) {
  return http.post(`${osApiURL}/api/v0.3/git-book/crawl`,params);
}

//博客git Book导入
export async function blogCrawlGitbook(params:any) {
  return http.post(`${osApiURL}/api/v0.3/git-book/blog/import/${params.code}?target=${params.gitBookMd}`);
}

//课程git Book导入状态
export async function gitbookStatus(params:any) {
  return http.get(`${osApiURL}/api/v0.3/git-book/crawl/status?code=${params.code}`);
}

//获取邀请学习权限
export async function inviteStudy(params: any) {
  const url = `${osApiURL}/api/v0.3/course/study/invite`
  return http.post(url, {
    repo: params.repo,
    callback: params.callback
  });
}

//获取课程详情用于共享学习
export async function getUserd(params: any) {
  const url = `${osApiURL}/api/v0.3/course/detail?repo=${params.repo}`
  return http.get(url);
}

// 获取课程目录创建模板
export async function getCourseStartupTemplate() {
  return http.get(`${osApiURL}/api/v0.3/config/file/templates`);
}

// 使用模板初始化课程目录
export async function courseStartup(params: any) {
  const url = `${osApiURL}/api/v0.3/course/template?tempId=${params.temp}&repo=${params.repo}`
  return http.post(url);
}

// 查询课程设置的模板
export async function getCourseTemplate(params: any) {
  const url = `${osApiURL}/api/v0.3/course/template`
  return http.get(url, {
    repo: params.repo
  });
}

// 获取课程内容类型
export async function getCourseType() {
  return http.get(`${osApiURL}/api/v0.3/config/file/types`);
}

// 更新课程标题
export async function updateCourse(params: any) {
  return http.post(`${osApiURL}/api/v0.3/course/update/${params?.code}`, params);
}

// 获取环境镜像
export async function getCourseImages() {
  // return http.get(`${osApiURL}/api/v0.3/config/file/images`);
  return http.get(`${config.baseURL}/repo/images`);
}

// 获取用户昵称
export async function getUserDetails(params: any) {
  return http.post(`${osApiURL}/api/v0.3/user/details`, {
    userIds: params.userIds
  });
}

// 学习：活动记录保存
export async function saveActive(params: any) {
  const url = `${osApiURL}/api/v0.3/course/activity/study/save`
  return http.post(url, {
    activityId: params?.activityId,
    chapterId: params?.chapterId,
    number: params?.number,
    repo: params?.repo,
    scope: params?.scope,
    topic: params?.topic,
    topicConfig: params?.topicConfig,
    topicResult: params?.topicResult,
    topicStatus: params?.topicStatus,
    userId: params?.userId
  });
}
// 获取学习态用户详情
export async function getActiveInfo(data: any) {
  return http.post(`${osApiURL}/api/v0.3/course/activity/study/info`, data)
}

// 学习：查询活动详情
export async function activityInfo(params: any) {
  return http.post(`${osApiURL}/api/v0.3/course/activity/study/info`, {
    activityId: params?.activityId,
    chapterId: params?.chapterId,
    repo: params?.repo,
    userId: params?.userId
  });
}

// 学习：活动记录列表
export async function activityList(params: any) {
  return http.post(`${osApiURL}/api/v0.3/course/activity/study/stats`, {
    chapterId: params?.chapterId,
    repo: params?.repo,
    userId: params?.userId
  });
}

// 学习活动记录统计查询
export async function statActive(params: any) {
  const url = `${osApiURL}/api/v0.3/course/activity/stat`
  return http.post(url, {
    chapter: params.chapter,
    number: params.number,
    repo: params.repo,
    topicConfig: params.topicConfig,
    topicResult: params.topicResult,
    userId: params.userId
  });
}

// 上传图片
export async function uploadImg(params: any) {
  return http.post(`${osApiURL}/api/v0.3/config/file/upload`, params.file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 获取端口图片
export async function getPortImg() {
  return http.get(`${osApiURL}/api/v0.3/config/picture/icon`)
}

// 获取实验室code
export async function getLabCode(params: any) {
  return http.get(`${osApiURL}/api/v0.3/course/${params?.code}`)
}

// 获取用户名称
export async function getuserName(id: string) {
  return http.get(`${osApiURL}/api/v0.3/user/detail/info?userId=${id}`)
}

// 保存答案      考试中保存运行部署选择 
export async function saveQuestion(params: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/study/workspace/save`, params)
}

// 保存答案   考卷中保存运行部署选择 
export async function saveExamQuestion(params: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/save`, params)
}

// 保存答案   题库中保存运行部署选择
export async function saveBankQuestion(params: any) {
  return http.post(`${osApiURL}/api/v0.3/question/bank/save`, params)
}


// 考试题目 查询详情   在考试中
export async function examQuestionDetail(params: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/study/detail`, params)
}

// 考卷题目 查询详情  创建考卷中
export async function examQuestionInfo(params: any) {
  return http.get(`${osApiURL}/api/v0.3/exam/question/info?questionCode=${params.questionCode}`,)
}

// 题库题目 查询详情  创建题目中
export async function bankQuestionInfo(params: any) {
  return http.get(`${osApiURL}/api/v0.3/question/bank/info?questionCode=${params.questionCode}`,)
}

// 问答----------------------------

// 问答分页查询
export async function getQuesAndAns(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/page`, data)
}

// 发布问答
export async function publishQuesAndAns(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/commit`, data)
}

// 发布问答
export async function getQuestionAnswerType() {
  return http.get(`${osApiURL}/api/v0.3/config/enums/QuestionAnswerType`)
}

// 获取社区前端域名
export async function getUrlConfig() {
  return http.get(`${osApiURL}/api/v0.3/config/tips?key=baseUrl`)
}

// 查询问答详情
export async function getDetail(labCode: string, couseCode: string, id: string) {
  return http.get(`${osApiURL}/api/v0.3/qa/problem/${couseCode}/${id}?labCode=${labCode}`)
}

// 分页查询回答详情的评论
export async function getDetailRecover(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/answer/page`, data)
}

// 回复回答的评论
export async function setDetailRecover(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/answer/commit`, data)
}

// 同步次issue
export async function syncIssue(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/sync/issue?cooperateUrl=${data?.cooperateUrl}`, data)
}
// issue同步状态查询
export async function syncIssueStatus(id: string) {
  return http.get(`${osApiURL}/api/v0.3/qa/problem/sync/issue/status/${id}`)
}

// 获取问答的同步状态
export async function getIssueStatus(id: any) {
  return http.get(`${osApiURL}/api/v0.3/qa/problem/sync/issue/status/${id}`)
}

// 删除问答
export async function delQues(data: any) {
  return http.post(`${osApiURL}/api/v0.3/qa/problem/delete`, data)
}

// 查询单个第三方令牌（查询用户绑定的第三方平台）
export async function queryUserToken(type: string) {
  return http.get(`${osApiURL}/api/v0.3/oauth/token/findOne`, { type })
}

// 查询实验室第三方令牌
export async function getLabToken(data: any) {
  return http.get(`${osApiURL}/api/v0.3/lab/token/find/one`, data)
}

// 修改课程环境
export async function changeCourseTemplate(code: string, template: any ) {
  return http.post(`${osApiURL}/api/v0.3/course/update/${code}`, { template })
}

// 工程项目类实操题 ------------------------
// 获取题目判分点
export async function getQuestionExampleList(params: any ) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/answer/example/list`, params)
}

// 设置分数
export async function saveExamProjectAns(data: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/answer/update/example/score`, data)
}

// 启动判定服务判定
export async function startJudgeJob(params: any ) {
  return http.post(`${osApiURL}/api/v0.3/judge/start/job`, params)
}

// 判定运行结果
export async function getJudgeTaskResult(params: any ) {
  return http.get(`${osApiURL}/api/v0.3/judge/task/result`, params)
}

// 修改考卷题目判分点/示例分数
export async function updateExampleScore(params: any ) {
  return http.post(`${osApiURL}/api/v0.3/exam/question/answer/update/example/score`, params)
}

type ShortChain = {
  repo: string, 
  callback: string,    // 回调地址
  inviteContentType: "wbStudyInvite" | "wbCreationInvite" // 工作台_学习侧_邀请协同 
}
// 获取短链
export async function applyShortChain<T extends ShortChain>(data: T) {
  return http.post(`${osApiURL}/api/v0.3/invite/content`, data)
}

// 考试数据上报服务
export async function repoExamLog(data: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/study/log/report`, data)
}

// 试卷列表
export async function getExamList(data: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/list/all`, data)
}

// 创建考试
export async function createPointExam(data: any) {
  return http.post(`${osApiURL}/api/v0.3/exam/repo/point/create`, data)
}

// 判断课程是否所属当前用户
export async function queryCourseBelong(repo: string) {
  return http.get(`${osApiURL}/api/v0.3/course/repo/owner`, {}, {params: {repo}})
}
